import { remoteConfigDefaults } from '@/plugins/firebase'
export default () => ({
  globalVariable: {
    PlusPayPaymentID: 5
  },
  windowWidth: 0,
  mobileWidth: 768,
  appVersion: {
    ios: remoteConfigDefaults.smartdaily_appversion_ios,
    android: remoteConfigDefaults.smartdaily_appversion_android
  },
  payment: {
    applePay: {
      id: 8,
      available: null,
      label: 'Apple Pay'
    },
    googlePay: {
      id: 7,
      available: null,
      label: 'Google Pay'
    }
  },
  toast: {
    isOpen: false,
    type: 'info',
    text: ''
  },
  imageBrowser: {
    isOpen: false,
    activeIndex: 0,
    imageList: []
  },
  registerSuccessDialog: false,
  bannerList: {
    homepage: null
  },
  serviceData: {},
  cityArea: [],
  allCityArea: [],
  channelTypeList: [],
  userDefaultCommunity: null,
  personalInfo: {
    receipt: {},
    address: [],
    community: [],
    contacts: []
  },
  meta: {
    title: '智樂家',
    titleTemple: ' | 智樂家',
    description: process.env.META_DESCRIPTION
  },
  routerSave: {},
  postBody: null,
  postError: null,
  agentOrder: {
    token: null,
    adminConsultationId: null,
    source: ''
  },
  guestPurchase: {
    createGuestAccountData: null,
    orderSuccess: { status: false, serviceId: null, orderId: null }
  },
  isGuestMember: null,
  serviceRatingDialogOpen: false,
  url: {
    line: 'line://ti/p/@ljs8129j' // line連結
  },
  sentryConfig: {
    tracing: false,
    tracingHeaderKey: 'X-Correlation-ID',
    tracingHeaderValue: ''
  },
  isFromFacebookAds: false,
  isApp: false,
  isTokenFromApp: false,
  canBeAppPageName: ['member-creditCardBinding', 'addCreditCard', 'payment-idHashCodeSn']
})
