// const script = document.createElement('script');
// script.text = `
// (function(e,t,n,o){e.PeopleEventsObject=o;e[o]=e[o]||{init:function(t){e[o].apiKey=t},
// setPerson:function(t,n){e[o].person=t;e[o].personTtl=n},
// forgetPerson:function(){e[o].toForgetPerson=true},
// track:function(){(e[o].q=e[o].q||[]).push(arguments)},
// updatePerson:function(t){e[o].personToUpdate={person:t}},
// appendToList:function(t,n){e[o].attributeToAppend={attributeName:t,attribute:n}}};
// var r=t.createElement("script");
// var s=t.getElementsByTagName("script")[0];r.async=1;r.src=n;s.parentNode.insertBefore(r,s)})
// (window,document,'https://s3.eu-central-1.amazonaws.com/portal-cdn-production/people-events-sdk/pe.latest-2.js','pe');

// pe.init('0c5cf67b474c8246bb920df174fcafde-82b1f4ae-4b5a-43be-b3d6-274dd72a1bef');
// `
// document.head.appendChild(script);

/**
 * script設定檔 目前透過 GTM 進行引入
 * 本地開發需要額外將 上述 script設定檔 引入 才可取用pe相關函式
 */

/**
 * infobip自訂方法 
 */

export default (context, inject) => {
  const infobipSetUser = () => {
    if (context.$auth.loggedIn) {
      if (typeof pe !== 'undefined' && pe) {
        const {
          APhone
        } = context.$auth.user
        pe.setPerson({ phone: `+8869${APhone.substring(2)}` })
      }
    } else {
      if (typeof pe !== 'undefined' && pe) {
        pe.forgetPerson()
      }
    }
  }

  const infobipForgetUser = () => {
    if (typeof pe !== 'undefined' && pe) {
      pe.forgetPerson()
    }
  }

  const infobipTrackEvent = (name, attr) => {
    if (typeof pe !== 'undefined' && pe) {
      pe.track(name, attr)
    }
  }

  inject('infobipSetUser', infobipSetUser)
  inject('infobipForgetUser', infobipForgetUser)
  inject('infobipTrackEvent', infobipTrackEvent)
}